import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export const Image = props => {
  const data = useStaticQuery(graphql`
    query {
      # the filter is useful if you have multiple source-filesystem instances
      # the name "images" is set in the gatsby-config
      images: allFile {
        edges {
          node {
            dir
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
  `)
  const requestedImages = data.images.edges.filter(element =>
    element.node.childImageSharp.fluid.originalName.match(
      `${props.filterByText}`
    )
  )
  return (
    <div className={props.className}>
      <Img
        fluid={requestedImages[0].node.childImageSharp.fluid}
        className={props.imgClassName}
      />
    </div>
  )
}
